import { forwardRef, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { BF_LENSES_STEP_TITLE, BUILD_FLOW_STEPS, LENSES_PACKAGES, LOCALE_DICT } from '@constants';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { Caption, Checkbox, EditabilityLensBody, Flex, Title } from '@components';
import { useBFContext } from '@context';
import { DISCOUNT_RATES, PRODUCT_TYPES, useIsVipMembership } from '@utils/index';
import { calculateDiscount } from '@utils/discount';
import { useTranslation } from '@utils/index';
import styles from './CustomLenses.module.scss';

const getTranslatedTexts = translator => {
	return {
		selectAllThatApply: translator('select-all-that-apply'),
	};
};

const CustomLenses = forwardRef<HTMLDivElement>(({}, ref) => {
	const endOfLenses = useRef<HTMLDivElement>(null);
	const { locale } = useRouter();
	const isBLLRTest = useFeatureIsOn('is-bl-lr-test');
	const {
		selectedLenses,
		lensPackage,
		BaseFrameVariant,
		variantPrices,
		rxOptions,
		rxType,
		handleCustomLens,
		step,
		previousBaseFrames,
		hasPreviousBaseFrames,
		isQuickAdd,
	} = useBFContext();
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.LENS);
	const currentLensOptions = rxOptions.find(option => option.optionValue === rxType)?.lenses ?? [];
	const preloadLensOptions = currentLensOptions.length > 0 ? currentLensOptions : rxOptions?.[0]?.lenses;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isBfAltCopy = useFeatureIsOn('is-bf-alt-copy');

	useEffect(() => {
		if (step === BUILD_FLOW_STEPS.CUSTOMIZE && !!lensPackage && !isQuickAdd) {
			setTimeout(() => {
				endOfLenses.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 50);
		}
	}, [step, lensPackage]);

	return (
		<Flex column gap={3} style={{ opacity: 1, paddingTop: isQuickAdd ? null : '1.6rem' }} ref={ref}>
			{!isQuickAdd && (
				<Flex column>
					<Title style={{ fontSize: '1.4rem', alignContent: 'center' }}>{BF_LENSES_STEP_TITLE.replace('2', '3')}</Title>
					<Caption small>({translations.selectAllThatApply})</Caption>
				</Flex>
			)}
			{/*Removes Premium Plus from options*/}
			{/*TODO extract to it's own component or reuse another component*/}
			{preloadLensOptions
				.filter(({ optionValue }) => {
					const removePremiumPlus = optionValue === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
					const removeLightResponsive =
						!isBLLRTest &&
						lensPackage === LENSES_PACKAGES.BASIC &&
						optionValue === BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE;

					return !(removePremiumPlus || removeLightResponsive);
				})
				.map(lens => {
					const isBlueLightOption = lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT;
					const isLightResponsiveSelected = selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
					const variantPriceDiff =
						BaseFrameVariant.state['Rx Type'] &&
						getVariantPriceDiff({
							lookup: variantPrices,
							current: BaseFrameVariant,
							selectedLenses,
							lens: lens.optionValue,
							currencyCode: LOCALE_DICT[locale].currencyCode,
							locale,
							lensPackage,
						});

					const price =
						isBlueLightOption && isLightResponsiveSelected
							? {
									amount: 0,
									currencyCode: LOCALE_DICT[locale].currencyCode,
									locale,
								}
							: BaseFrameVariant.data.variantBySelectedOptions.title.includes(lens.optionValue)
								? variantPriceDiff?.without
								: variantPriceDiff?.with;

					const discountedPrice = calculateDiscount(
						applyDiscountMembership ? DISCOUNT_RATES.PERCENT_15 : 0,
						price.amount
					);

					const isPreviouslyOrdered =
						hasPreviousBaseFrames && previousBaseFrames[0].lensType.includes(lens.optionValue);

					return (
						<Checkbox
							dataTags={{ 'data-lens-type': lens.optionValue }}
							key={lens.name}
							option={lens.name}
							checked={
								selectedLenses.includes(lens.optionValue) ||
								(selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) &&
									lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT)
							}
							hoverable
							hoverColor='gray'
							className={!!lensPackage ? styles['lens-checkbox'] : ''}
							handler={() => {
								if (!!lensPackage) {
									handleCustomLens(lens.optionValue);
								}
							}}
							setChecked={
								selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) &&
								lens.optionValue === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT
									? () => null
									: null
							}
						>
							<EditabilityLensBody
								selectedLenses={selectedLenses}
								name={isBfAltCopy ? lens.altCopy.title : lens.name}
								tag={lens.tag}
								price={{ ...price, amount: discountedPrice }}
								copy={isBfAltCopy ? lens.altCopy.description : lens.description}
								usePlusSign
								isPreviouslyOrdered={isPreviouslyOrdered}
							/>
						</Checkbox>
					);
				})}
			<div id='endOfLenses' ref={endOfLenses} />
		</Flex>
	);
});

CustomLenses.displayName = 'CustomLenses';

export default CustomLenses;
