import { useRouter } from 'next/router';
import { SUN_TOPS_PROPOSITIONS_LIST } from '@constants';
import { Paragraph, Title } from '@components';
import { useContentfulAsset } from '@services/contentful';
import { ImageTitleTextStackEntrySkeletonType } from '@ts/contentful';
import styles from './SunTopInfoArea.module.scss';

const SunTopInfoArea = () => {
	const { locale } = useRouter();
	const SunTopsPropositions = useContentfulAsset<ImageTitleTextStackEntrySkeletonType>({
		id: SUN_TOPS_PROPOSITIONS_LIST.map(({ id }) => id).join('+'),
		query: {
			'content_type': 'imageTitleTextStack',
			'fields.internalName[in]': SUN_TOPS_PROPOSITIONS_LIST.map(({ id }) => id).join(','),
			locale,
		},
	});

	const mappedPropositionList = SunTopsPropositions?.data?.map(
		({
			fields: {
				description: copy,
				heading: title,
				image: {
					fields: {
						file: { url: src },
					},
				},
			},
		}) => (
			<li key={title} className={styles.listItem}>
				<img src={`https:${src}`} alt={title} height={96} width={96} />
				<div className={styles.descriptionContainer}>
					<Title>{title}</Title>
					<Paragraph>{copy}</Paragraph>
				</div>
			</li>
		)
	);

	return (
		<section className={styles.container} data-section-name='value-proposition'>
			<Title>About our Sun Tops</Title>
			<ul>{mappedPropositionList}</ul>
		</section>
	);
};

export default SunTopInfoArea;
