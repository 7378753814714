import { memo, useCallback, useRef } from 'react';
import cn from 'classnames';
import { MODEL_LIFESTYLE_IMAGE, PRODUCT_TYPES } from '@constants';
import { Button, Flex, Grid, Loading, Modal, Slide, VideoSlide, VideoIcon } from '@components';
import { CarouselProps } from '@ts/components';
import { generateTopFrameImages } from '@utils/images';
import styles from './ImageGallery.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		virtualTryOn: translator('virtual-try-on'),
	};
};

const ImageGallery = ({
	images,
	imageLoadingHandler,
	name,
	secondary,
	tags,
	type,
	video,
	isImageLoading = false,
	isSecondaryImageLoading = false,
	className,
	mode = 'pdp',
	product,
	variant,
	topFrameOnModal,
	imageLayoutType = 'none',
	VTODispatch,
}: CarouselProps) => {
	const loadingRef = useRef(null);
	const isSingColumn = imageLayoutType === 'single';
	const isDoubleColumn = imageLayoutType === 'double';
	const isImagesLaidOut = isSingColumn || isDoubleColumn;
	const { topFrameVariant, copyTopFrameVariant } = generateTopFrameImages(secondary, isImageLoading, images, mode, type);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const withThreeFourthImage = copyTopFrameVariant?.length === 5;
	const topFrameVariantWithLifestyle = [
		...copyTopFrameVariant,
		...(!isImageLoading && topFrameOnModal?.length > 0
			? [{ primary: topFrameOnModal[0]?.url, secondary: MODEL_LIFESTYLE_IMAGE }]
			: []),
	];

	const isFixedHeightContainer = isSingColumn && type.includes(PRODUCT_TYPES.BASE_FRAME);

	const videoSlide = video && (
		<Flex
			key='video-slide'
			className={cn(styles['swiper-slide'], {
				[styles['full-width-video-slide']]: isDoubleColumn,
				[styles['fixed-height-container']]: isFixedHeightContainer,
			})}
		>
			<VideoSlide video={video} type={type} name={name} isImagesLaidOut={isImagesLaidOut} />
		</Flex>
	);

	const mappedTopFrameSlides = useCallback(() => {
		const classes = cn(styles['swiper-slide'], {
			[styles['with-3-4-image']]: withThreeFourthImage,
			[styles['without-3-4-image']]: !withThreeFourthImage,
		});
		const slides =
			topFrameVariantWithLifestyle?.map(({ primary, secondary }, index) => {
				return (
					<Flex key={`top-image-slide-${index}`} className={classes} data-image-slide-index={index}>
						<Slide
							index={index}
							imageLoadingHandler={imageLoadingHandler}
							name={name}
							primary={primary}
							secondary={secondary}
							type={primary ? type : 'LIFESTYLE'}
							imageLayoutType={imageLayoutType}
						/>
					</Flex>
				);
			}) || [];
		return slides.concat(videoSlide);
	}, [topFrameVariantWithLifestyle, imageLoadingHandler, name, topFrameVariant, type, withThreeFourthImage]);

	const mappedSwiperSlides = useCallback(() => {
		const slides =
			images?.map(({ url, altText, height, width }, index) => {
				return (
					<Flex
						key={`image-slide-${index}`}
						data-image-slide-index={index}
						className={cn(styles['swiper-slide'], { [styles['fixed-height-container']]: isFixedHeightContainer })}
					>
						<Slide
							index={index}
							type={altText?.includes('lifestyle') ? 'LIFESTYLE' : type}
							name={name}
							imageLoadingHandler={imageLoadingHandler}
							url={url}
							variant={variant}
							tags={tags}
							height={height}
							width={width}
							imageLayoutType={imageLayoutType}
						/>
						{VTODispatch && ((index === 1 && isDoubleColumn) || (index === 0 && isSingColumn)) && (
							<Modal.Trigger asChild>
								<Flex justify='center'>
									<Button
										color='white'
										extraClasses={styles.vtoButton}
										data-tryon={name}
										onClick={() =>
											VTODispatch({
												type: 'product',
												option: {
													product,
													variantIdentifier: variant?.option,
												},
											})
										}
									>
										<VideoIcon /> {translations.virtualTryOn}
									</Button>
								</Flex>
							</Modal.Trigger>
						)}
					</Flex>
				);
			}) || [];
		return slides.concat(videoSlide);
	}, [imageLoadingHandler, images, name, secondary, topFrameVariant, type, withThreeFourthImage]);

	return (
		<Grid
			columns={isSingColumn || (name === PRODUCT_TYPES.GIFT_CARD && isDoubleColumn) ? 1 : 2}
			gap={4}
			className={cn(styles['image-grid-container'], className)}
			data-type={type}
		>
			{secondary ? mappedTopFrameSlides() : mappedSwiperSlides()}
			{isImageLoading || (isSecondaryImageLoading && <Loading ref={loadingRef} className={styles['image-loader']} />)}
		</Grid>
	);
};

export default memo(ImageGallery);
