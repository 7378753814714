/* eslint-disable max-lines */
import { forwardRef } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import {
	BADGE_TYPES,
	BASE_FRAME_NAMES,
	CUSTOMER_JOURNEYS,
	LARGE_WIDTH,
	LENS_COLORS,
	MEDIUM_WIDTH,
	NEW_BASE_FRAME_NAMES,
	PRODUCT_TYPES,
} from '@constants';
import variables from '@styles/export.module.scss';
import { ComponentProps, frameSwatchesProps } from '@ts/components';
import { NormalizedProduct, NormalizedVariant, VariantControl } from '@ts/product';
import { YotpoReviews } from '@ts/yotpo/rewards';
import {
	About,
	Breadcrumbs,
	Button,
	CompareAtPrice,
	ErrorBoundary,
	FavoriteIcon,
	Flex,
	Heading,
	KlarnaPlacement,
	LabelText,
	Lozenge,
	LozengeProps,
	NavItemTypography,
	Paragraph,
	ProductTags,
	ProductViewCTA,
	ProductViewDescription,
	ReApplyLenses,
	SidebarLayout,
	Spacer,
	SunTopInfoArea,
	SwatchController,
	SwatchSkeleton,
	TagsSkeleton,
	YotpoStars,
} from '@components';
import { useIsMobile, useIsProductVariantSoldOut } from '@utils/hooks';
import QuickAdd from '@components/buildflow/QuickAdd';
import { PreviousBaseFrame } from '@ts/re-apply-lenses';
import { BaseVariantPriceDictionary } from '@utils/constants/base-skus';
import { capitalizeEachWord } from '@utils/strings';
import styles from './ProductViewSidebar.module.scss';

type ProductViewProps = ComponentProps<HTMLDivElement> & {
	aboutProps: Parameters<typeof About>[0];
	buttonProps: Parameters<typeof Button>[0] & { hasSubtotal?: boolean; isLoading?: boolean; availableForSale?: boolean };
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	frameShape?: (typeof BASE_FRAME_NAMES)[number];
	productType: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	product?: NormalizedProduct;
	selectedVariant?: NormalizedVariant;
	productTags?: LozengeProps[];
	primaryController: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	secondaryController?: VariantControl;
	colorController?: VariantControl;
	collectionController?: VariantControl & {
		frameSwatches?: frameSwatchesProps;
	};
	subtotal: number;
	path: string;
	name: string;
	yotpo?: YotpoReviews;
	shipmentInfo?: string;
	variantsAvailable?: Set<string>;
	isSunGlasses?: boolean;
	selectedMaterial?: string;
	handleMaterialChange?: (material: string, frameColor: string) => void;
	secondaryButtonProps?: Parameters<typeof Button>[0];
	previousBaseFrames?: PreviousBaseFrame[];
	variantPrices?: BaseVariantPriceDictionary;
	frame?: NEW_BASE_FRAME_NAMES;
	journey?: CUSTOMER_JOURNEYS;
	readerRx?: string;
	priceTag?: typeof BADGE_TYPES.TOP_RATED;
	tag?: typeof BADGE_TYPES.TOP_RATED;
	isScrolled?: boolean;
	isStickySidebar?: boolean;
};

const Sidebar = forwardRef<HTMLDivElement, ProductViewProps>(
	(
		{
			aboutProps,
			buttonProps,
			frameShape,
			updateFrameShape,
			productType,
			productTags,
			primaryController,
			secondaryController = null,
			colorController = null,
			collectionController = null,
			subtotal,
			path,
			product,
			selectedVariant,
			name,
			yotpo,
			shipmentInfo,
			variantsAvailable = new Set([]),
			isSunGlasses = false,
			selectedMaterial = '',
			handleMaterialChange,
			secondaryButtonProps,
			previousBaseFrames,
			variantPrices,
			journey,
			readerRx,
			frame,
			priceTag,
			tag,
			...rest
		},
		forwardedRef
	) => {
		const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
		const isTablet = useIsMobile({ maxWidth: LARGE_WIDTH });
		const isQuickAdd = useFeatureIsOn('is-quick-add') && !isTablet;
		const isKlarnaTestActive = useFeatureIsOn('klarna-prominence');
		const isReApplyLensesTest1Active = useFeatureIsOn('is-re-apply-lenses-ux1-test');
		const isBaseFrame = productType.includes(PRODUCT_TYPES.BASE_FRAME);
		const price = selectedVariant?.price?.amount ?? product.price.amount;
		const { checkIfSoldOut } = useIsProductVariantSoldOut();
		const isSoldOut = checkIfSoldOut(product.handle, selectedVariant?.option);
		const isFrameType = [PRODUCT_TYPES.BASE_FRAME, PRODUCT_TYPES.TOP_FRAME].some(type => productType.includes(type));
		const isAccessory = productType.includes(PRODUCT_TYPES.ACCESSORY);
		const showNewCta = rest.isStickySidebar && isTablet && (isFrameType || isAccessory) && !isSunGlasses;

		return (
			<SidebarLayout.Sidebar ref={forwardedRef} pdpOptimizationStyle {...rest}>
				<>
					{tag && (
						<Lozenge key={`${tag.name}`} {...tag} shape='square' extraClasses={styles.tag}>
							{capitalizeEachWord(tag.displayText)}
						</Lozenge>
					)}
					<Breadcrumbs path={path} />
					<ErrorBoundary skeleton={<TagsSkeleton />}>
						{productType.includes(PRODUCT_TYPES.TOP_FRAME) && <ProductTags productTags={productTags} />}
					</ErrorBoundary>
					<Flex align='center' justify='between'>
						<Heading tag='h2'>{name}</Heading>
						<div data-pdp-favorite={name}>
							{product && !isSoldOut && <FavoriteIcon product={product} variant={selectedVariant} shape='circle' />}
						</div>
					</Flex>
					<CompareAtPrice
						compareAtPrice={selectedVariant?.compareAtPrice ?? null}
						price={selectedVariant?.price ?? product?.price}
						useLabel={isBaseFrame}
						tag={
							priceTag && {
								...priceTag,
								extraClasses: cn(styles['price-tag'], {
									[styles['price-tag--promo']]: priceTag?.name == 'vip-promo',
								}),
							}
						}
					/>
					{isKlarnaTestActive && <KlarnaPlacement subtotal={price} />}

					{shipmentInfo && (
						<LabelText style={{ color: variables.orange2, marginTop: '1.6rem' }}>{shipmentInfo}</LabelText>
					)}
					<YotpoStars yotpo={yotpo} />
					<ProductViewDescription
						primaryController={primaryController}
						aboutProps={aboutProps}
						name={name}
						secondaryController={secondaryController}
						colorController={colorController}
						updateFrameShape={updateFrameShape}
						variantsAvailable={variantsAvailable}
						productType={productType}
						selectedMaterial={selectedMaterial}
						handleMaterialChange={handleMaterialChange}
						frameShape={frameShape}
					/>
					{isReApplyLensesTest1Active && (
						<ReApplyLenses
							handle={product.handle}
							frameColor={primaryController?.selected}
							previousBaseFrames={previousBaseFrames}
							variantPrices={variantPrices}
							journey={journey}
							lensColor={(secondaryController?.selected as LENS_COLORS) ?? null}
							product={product}
							readerRx={readerRx}
							frame={frame}
						/>
					)}
					<ErrorBoundary skeleton={<SwatchSkeleton />}>
						{collectionController && (
							<SwatchController
								alignment='left'
								options={collectionController?.options}
								callback={collectionController?.callback}
								selected={collectionController?.selected}
								name={name}
								type='option'
								title={collectionController?.title}
								frameSwatches={collectionController?.frameSwatches}
							/>
						)}
					</ErrorBoundary>
					{!isSunGlasses && isBaseFrame && isQuickAdd && <QuickAdd />}
					{isBaseFrame && isMobile && (
						<>
							<NavItemTypography shouldKeepTypography>About {name}</NavItemTypography>
							<Paragraph>{aboutProps?.description}</Paragraph>
							<Spacer size={'1.6rem'} />
						</>
					)}
					<ProductViewCTA
						product={product}
						productType={productType}
						aboutProps={aboutProps}
						name={name}
						primaryController={primaryController}
						buttonProps={buttonProps}
						subtotal={subtotal}
						selectedVariant={selectedVariant}
						colorController={colorController}
						updateFrameShape={updateFrameShape}
						frameShape={frameShape}
						variantsAvailable={variantsAvailable}
						isSunGlasses={isSunGlasses}
						lensColor={(secondaryController?.selected as LENS_COLORS) ?? null}
						secondaryButtonProps={secondaryButtonProps}
						tag={priceTag}
						showFloatingCTAAlways={isQuickAdd}
						showNewSticky={showNewCta}
						collectionController={collectionController}
					/>
					{productType.includes(PRODUCT_TYPES.TOP_FRAME) && name.includes('Sun Top') && <SunTopInfoArea />}
				</>
			</SidebarLayout.Sidebar>
		);
	}
);

Sidebar.displayName = 'Sidebar';

export default Sidebar;
