import { BASE_FRAME_NAMES } from '@constants';
import { VariantControl } from '@ts/product';
import { Button, Flex, FrameColorOptions, FrameShapeSelect, Paragraph } from '@components';
import { PRODUCT_TYPES } from '@constants';
import styles from './BaseFrameShapeColor.module.scss';
import { useTranslation } from '@utils/index';

type BaseFrameShapeColorProps = {
	callback: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	primaryController: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	variantsAvailable?: Set<string>;
	colorController?: VariantControl;
	productType?: string;
	frameShape?: (typeof BASE_FRAME_NAMES)[number];
};

const getTranslatedTexts = translator => {
	return {
		baseFrameShape: translator('base-frame-shape'),
	};
};

function BaseFrameShapeColor({
	callback,
	primaryController,
	variantsAvailable,
	colorController,
	productType = PRODUCT_TYPES.TOP_FRAME,
	frameShape,
}: BaseFrameShapeColorProps) {
	const isTopFrameBundle = productType.includes('BUNDLE');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Flex gap={3} className={styles.editContainer}>
			<>
				<div className={styles['frameSelector']} data-frame-shape-selector>
					<Paragraph>{translations.baseFrameShape}</Paragraph>
					<FrameShapeSelect
						callback={callback}
						buttonProps={primaryController.buttonProps}
						variantsAvailable={variantsAvailable}
					/>
				</div>
				{colorController && !isTopFrameBundle && (
					<Flex className={styles.editColor}>
						<FrameColorOptions
							title={colorController?.title}
							options={colorController?.options}
							selected={colorController?.selected}
							variantImages={colorController?.variantImages}
							callback={colorController?.callback}
							frameShape={frameShape ?? ''}
						/>
					</Flex>
				)}
			</>
		</Flex>
	);
}

export default BaseFrameShapeColor;
