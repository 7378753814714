import { memo, useMemo } from 'react';
import Link from 'next/link';
import { capitalizeEachWord, combineAccumulatively } from '@utils/strings';
import styles from './Breadcrumbs.module.scss';
import { translate } from '@utils/locales';
import { useRouter } from 'next/router';

type BreadcrumbsProps = {
	path: string;
};

function normalizeLabel(label: string) {
	if (label.includes('?')) label = label.split('?')[0];
	const options = {
		'Base Frame 2': 'The Serra',
		'Base Frame 3': 'The Twain',
		'Large Base': 'The Larkin',
		'Medium Rectangular Base': 'The Otero',
		'Best Sellers 1': 'Best Sellers',
		'Wedding': 'Special Occasion',
	};

	return options[label] ?? label;
}

function getParentCollection(path) {
	const queryString = path.split('?')[1];
	const params = new URLSearchParams(queryString);
	return params.get('parentCollection');
}

function createCrumbs(path: string, locale: string) {
	const parentCollection = getParentCollection(path);
	const splitPaths = path.split('/');
	splitPaths.pop();
	if (!!parentCollection) {
		splitPaths[splitPaths.length - 1] = parentCollection;
	}
	const routes = combineAccumulatively(splitPaths);
	const labels = splitPaths.map(str => capitalizeEachWord(str.split('-').join(' ')));

	return labels.map((label, index) => ({
		label: normalizeLabel(label) || translate('home', locale),
		route: routes[index],
	}));
}

const Breadcrumbs = ({ path = '/eyeglasses' }: BreadcrumbsProps) => {
	const { locale } = useRouter();
	const crumbs = useMemo(() => createCrumbs(path, locale), [path]);

	return (
		<ul className={styles.breadcrumbs} data-breadcrumbs>
			{crumbs.map(({ label, route }) => (
				<Link key={label} href={route} title={label} className={styles.breadcrumb} data-crumb-route={label}>
					<span>{label}</span>
				</Link>
			))}
		</ul>
	);
};

export default memo(Breadcrumbs);
